import { Turbo, cable } from "@hotwired/turbo-rails"
import "particles.js"

require("@rails/activestorage").start()

import "trix"
import "@rails/actiontext"

import "./abraham.js"

import "./controllers"
import "./commons.js"
